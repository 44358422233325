import PropTypes from "prop-types";
import React from "react";
import { Box, Heading, Text } from "rebass/styled-components";
import Container from "./Container";
import { withTheme } from "styled-components";
import { HmacSHA1 } from "crypto-js";

const HeaderPage = ({ title, theme, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        "&:before, &:after": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          right: 0,
          width: "50%",
          height: "100%",
          bg: "grayLight",
          zIndex: 1
        },
      }}
    >
      <Container
      py={[4,5,8]}
      sx={{
        background: theme.colors.gradiantTitle,
        position: "relative",
        zIndex: 2,
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          left: [0,"50%"],
          width: ["100vw","50vw"],
          height: "100%",
          backgroundImage: "url('/img/bg-h1.svg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          bg: "transparent",
          zIndex: 3
        }
      }}>
        <Heading
          as="h1"
          variant="h1"
          sx={{
            position: "relative",
            zIndex: 4
          }}
        >
          {title}
        </Heading>
      </Container>
    </Box>
  );
};

HeaderPage.propTypes = {
  title: PropTypes.any,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      gradiantBanner: PropTypes.any
    })
  })
};

export default withTheme(HeaderPage);
