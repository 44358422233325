import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import HeaderPage from "../components/UI/HeaderPage";
import CustomBreadcrumb from "../components/UI/CustomBreadcrumb";
import { Box } from "rebass/styled-components";

const Page = ({
  children,
  title,
  description,
  id,
  breadcrumb,
  customCrumbLabel,
  hideBreadCrumb,
  lang
}) => {
  hideBreadCrumb = hideBreadCrumb || false;
  return (
    <Layout lang={lang}>
      <SEO title={title} description={description} />
      {id !== "index" && 
        id !== "home" && (
          <HeaderPage title={title} />
        )
      }
      {id !== "index" && 
        id !== "home" && (
          !hideBreadCrumb && (
            <CustomBreadcrumb data={breadcrumb} customCrumbLabel={customCrumbLabel} lang={lang} />
          )
        )
      }      
      <Box id="page--content">{children}</Box>
    </Layout>
  );
};
Page.propTypes = {
  children: PropTypes.any,
  description: PropTypes.any,
  id: PropTypes.string,
  title: PropTypes.any,
  breadcrumb: PropTypes.any
};
export default Page;
