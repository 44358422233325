import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import CustomImage from "./UI/CustomImage";
import Markdown from "./UI/Markdown";
import Fontello from "./UI/Fontello";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import Section from "./UI/Section";

import loadable from "@loadable/component";
const Map = loadable(() => import("./Blocks/InfosMap/Map"));

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt, lang }) => {
  logoAlt = logoAlt || "Logo";
  var telLink = "tel:";
  var mailTo = "mailto:";

  if (!menusSettings.activeFooter) {
    return <></>;
  }
  const footermenuItems = lang == "en" ? menusSettings.footermenuen : menusSettings.footermenu;
  return (
    <Section bg="footer" color="muted" pb={[0, 0, 0, 0]} pt={[0, 0, 0, 0]}>
      <Container py={[4, 4, 4]} bg="footer">
        <Flex
          variant="grid"
          justifyContent="space-between"
          flexDirection={["column", "column", "row"]}
          flexWrap="inherit"
        >
          <Box
            variant="gridItem"
            width={[1,1,1/2]}
            sx={{
              "p": {
                m: 0
              },
              "i": {
                color: "primary",
                fontSize: 8,
                "&:before": {
                  ml: 0
                }
              },
              "a": {
                color: "muted",
                "&:hover": {
                  color: "white"
                }
              }
            }}
          >
            <CustomImage
              img={footerSettings.logoFooter}
              alt={logoAlt}
              width="100%"
              maxWidth="200px"
              mb={8}
            />
            <Flex mb={6}>
              <Box mr={3}>
                <Fontello icon="poi" />
              </Box>
              <Markdown>{footerSettings.addressFooter}</Markdown>
            </Flex>
            <a href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}>
              <Flex alignItems="center" mb={1}>
                <Box mr={3}>
                  <Fontello icon="phone" />
                </Box>
                <Markdown>{footerSettings.phoneFooter}</Markdown>
              </Flex>
            </a>
            <Flex alignItems="center" mb={6}>
              <Box mr={3}>
                <Fontello icon="fax" />
              </Box>
              <Markdown>{footerSettings.faxFooter}</Markdown>
            </Flex>
            <a href={mailTo + footerSettings.mailFooter}>
              <Flex alignItems="center" mb={6}>
                <Box mr={3}>
                  <Fontello icon="mail" />
                </Box>
                <Markdown>{footerSettings.mailFooter}</Markdown>
              </Flex>
            </a>
          </Box>

          <Box variant="gridItem" width={[1,1,1/2]}>
            {footerSettings.position && <Map position={footerSettings.position} style="dark-v10" />}
          </Box>

        </Flex>
      </Container>
      <Box bg="footerBottom">
        <Container pb={[0, 0, 0]}>
          <Flex
            justifyContent="space-between"
            flexDirection={["column", "row"]}
            fontSize={1}
            color="muted"
            py={[6, 3]}
            textAlign={["center", "center", "left"]}
            sx={{
              a: {
                "&:hover": {
                  color: "primary",
                  textDecoration: "underline",
                  button: {
                    textDecoration: "underline"
                  }
                },
                "&.active": {
                  color: "primary"
                }
              }
            }}
          >
            <Box>
              <Flex flexDirection={["column", "row"]}>
                {footermenuItems.map((item, i) => (
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                    key={"menu-" + i}
                  >
                    <Button variant="ninja" mr={[0, 6, 6]}>
                      <Text mb={[3, 0]}>{item.title}</Text>
                    </Button>
                  </Link>
                ))}
              </Flex>
            </Box>
            <Box pt={[6, 0, 0]}>
              <>
                Réalisation :{" "}
                <a href="https://la-fabrik.nc">
                  <strong>La Fabrik</strong>
                </a>
              </>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    addressFooter: PropTypes.any,
    faxFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    footermenu: PropTypes.array,
    footermenuen: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme, lang }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeFooter
              position
              footermenu {
                external
                offset
                path
                title
              }
              footermenuen {
                external
                offset
                path
                title
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              addressFooter
              phoneFooter
              faxFooter
              mailFooter
            }
          }
        }
      }
    `
  );
  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
      lang={lang}
    />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
