import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Icofont from "./UI/Icofont";
import Link from "./UI/Link";
import LangSwitcher from "./UI/LangSwitcher";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "./UI/CustomImage";
import loadable from "@loadable/component";
import Fontello from "./UI/Fontello";

const Sticky = loadable(() => import("react-stickynode"));
const Drawer = loadable(() => import("./Drawer"));

const NavbarRaw = ({ theme, menusSettings, servicesList, logoAlt, lang }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  const toggleSub = () => {
    setSubOpen(!subOpen);
  };
  console.log(menusSettings);
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  const mainmenuItems = lang == "en" ? menusSettings.mainmenuen : menusSettings.mainmenu;
  const footermenuItems = lang == "en" ? menusSettings.footermenuen : menusSettings.footermenu;
  return (
    <Sticky top={0} innerZ={10000} onStateChange={status => setStiked(status.status > 0)}>
      <Box variant="navbar" className="sticky" bg="#fff">
        <Box
        height="100%"
        width={open ? "100vw" : "100%"}>
          <Container height="100%" sx={{"&>div":{height:"100%"}}}>
            <Flex
            alignItems="center"
            height="100%"
            sx={{position:"relative"}}>
              <Link className="logo-link" href="/">
                <CustomImage
                  width={["112px", "171px","210px"]}
                  height="auto"
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                />
              </Link>
              <Box mx="auto" />
              <Box
              alignItems="center"
              justifyContent="space-between"
              display={["none", "none", "none", "flex"]}
              width="100%"
              pl="32%"
              sx={{
                "a.active": {
                  color: "primary"
                }
              }}>
                {mainmenuItems
                  .filter(item => item.inNavBar)
                  .map((item, i) => (
                    <Box 
                    key={"menu-" + i}
                    sx={{
                      position: "relative",
                      "& > a": {
                        py: 4,
                        display: "block",
                      },
                      "& > div.sub-menu": {
                        mt: -3,
                        opacity:0
                      },
                      "&:hover":{
                        "& > div.sub-menu": {
                          mt:-1,
                          opacity:"1",
                          visibility: "visible",
                        },
                      }
                    }}>
                      <Link
                      activeClassName="active"
                      href={item.path}
                      external={item.external}
                      isHomeLink={item.path == "/en" ? true : false}
                      >
                        <Button
                        variant="menuItem"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%"
                        }}>
                          <Text>{item.title}</Text>
                          {item.path.includes("/services") && (
                            <Box ml={2}
                            sx={{
                              "& > i:before": {
                                fontSize: 6
                              }
                            }}>
                              <Fontello icon="chevron-down" />
                            </Box>
                          )}
                        </Button>
                      </Link>
                      {item.path.includes("/services") && (
                        <Flex
                        flexDirection="column"
                        className="sub-menu"
                        sx={{
                          position: ["inherit","inherit","absolute"],
                          visibility: ["visible","visible","hidden"],                       
                          transition: "200ms all ease",
                          boxShadow: "header",
                          bg: "white",
                          p: 2,
                          mt: -1,
                          ml:-4,
                          "a": {
                            p:2,
                            "&:hover": {
                              color: "primary"
                            }
                          }
                        }}>
                          {servicesList.map((item, i) => (
                            item.node.frontmatter.language == lang && (
                              // console.log(item.node.fields.slug),
                              <Link
                              activeClassName="active"
                              href={lang == "en" ? "/en"+item.node.fields.slug : item.node.fields.slug}
                              external={false}
                              key={"sub-services-" + i}
                              >
                                  <Button variant="menuItem">
                                    <Text sx={{whiteSpace:"nowrap"}}>{item.node.frontmatter.title}</Text>
                                </Button>
                              </Link>
                            )
                          ))}
                        </Flex>
                      )}
                    </Box>
                  ))}
              </Box>
              <Box
              ml="10"
              display={["none", "none", "none", "flex"]}
              justifyContent="flex-end"
              alignSelf="flex-start"
              mt="20px">
                <LangSwitcher lang={lang} />
              </Box>
              <Drawer
                closeButton={
                  <Box textAlign="right">
                    <Button variant="ninja" p={2} fontSize={11}>
                      <Fontello icon="close" />
                    </Button>
                  </Box>
                }
                placement="top"
                drawerHandler={
                  <Button variant="ninja" pl={2} display={["block", "block", "block", "none"]} fontSize={11}>
                    <Fontello icon={open ? "close" : "menu"} />
                  </Button>
                }
                open={open}
                toggleHandler={toggleHandler}
              >
                <Flex width={["100%", "100%"]} pb={6} flexDirection="column">
                  <Box
                  pb={6}
                  display="flex"
                  justifyContent="flex-end"
                  pt={3}
                  pr={2}>
                    <LangSwitcher lang={lang} />
                  </Box>
                  {mainmenuItems
                    .filter(item => item.inSideBar)
                    .map((item, i) => (
                      <Box
                      textAlign="center"
                      key={i}
                      mb={4}
                      sx={{
                        "a": {
                          display: "block",
                          "&:hover": {
                            bg: "background"
                          },
                          "&.active": {
                            color: "primary"
                          }
                        }
                      }}>
                        <Link
                        href={item.path}
                        external={item.external}
                        onClick={toggleHandler}>
                          <Button
                            variant="menuItem"
                            p={2}
                            textAlign={["center", "center", "left"]}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%"
                            }}
                          >
                            <Text>{item.title}</Text>
                          </Button>
                        </Link>
                        {/* {item.path == "/services" && (
                          <Box>
                              <Button
                                variant="menuItem"
                                p={2}
                                textAlign={["center", "center", "left"]}
                                onClick={toggleSub}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%"
                                }}
                              >
                                <Text>{item.title}</Text>
                                {item.path == "/services" && (
                                  <Box ml={2}
                                  sx={{
                                    "& > i:before": {
                                      fontSize: 6
                                    }
                                  }}>
                                    <Fontello icon="chevron-down" />
                                  </Box>
                                )}
                              </Button>
                            <Flex
                            flexDirection="column"
                            bg="grayLight"
                            mb={4}
                            sx={{
                              "a": {
                                display: "block",
                                py: 4,
                                "&:hover": {
                                  bg: "gray"
                                },
                                "&.active": {
                                  color: "primary"
                                }
                              }
                            }}>
                              {servicesList.map((item, i) => (
                                <Link
                                activeClassName="active"
                                href={item.node.fields.slug}
                                external="false"
                                key={"sub-services-" + i}
                                >
                                  <Button variant="menuItem"
                                  sx={{
                                    textTransform: "inherit"
                                  }}>
                                    <Text>{item.node.frontmatter.title}</Text>
                                  </Button>
                                </Link>
                              ))}
                            </Flex>
                          </Box>
                        )} */}
                      </Box>
                    ))}
                    <Flex
                    flexDirection="column"      
                    justifyContent="center"              
                    fontSize={1}
                    color="muted"
                    sx={{
                      position: "relative",
                      "&:before": {
                        content: "''",
                        width: "130px",
                        height: "1px",
                        mx: "auto",
                        display: "block",
                        bg: "grayLight",
                        mb:8,
                        mt:2
                      },
                      "a": {
                        "&:hover": {
                          color: "primary",
                          textDecoration: "underline",
                          "button": {
                            textDecoration: "underline"
                          }
                        },
                        "&.active": {
                          color: "primary"
                        }
                      }
                    }}>
                      {footermenuItems.map((item, i) => (
                        <Box textAlign="center" key={"menufooter-" + i}>
                          <Link
                            activeClassName="active"
                            href={item.path}
                            external={item.external}
                          >
                            <Button variant="ninja" mb={4}>
                              <Text>{item.title}</Text>
                            </Button>
                          </Link>
                        </Box>
                      ))}
                    </Flex>
                </Flex>
              </Drawer>
            </Flex>
          </Container>
        </Box>
      </Box>
    </Sticky>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    mainmenuen: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme, lang }) => {
  const { markdownRemark, site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { eq: "menus" } }) {
          frontmatter {
            activeTopMenu
            logo2 {
              publicURL
              childImageSharp {
                fluid(maxHeight: 75, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            logo {
              publicURL
              childImageSharp {
                fluid(maxHeight: 75, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            mainmenu {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
            mainmenuen {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
            footermenu {
              external
              offset
              path
              title
            }
            footermenuen {
              external
              offset
              path
              title
            }
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "ServiceTemplate" } } }
          sort: { fields: [frontmatter___weight], order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                language
              }
            }
          }
        }
      }
    `
  );

  const menusSettings = markdownRemark.frontmatter || {};
  const servicesList = allMarkdownRemark.edges || {};
  return (
    <NavbarRaw theme={theme} menusSettings={menusSettings} servicesList={servicesList} logoAlt={site.siteMetadata.title} lang={lang} />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default withTheme(Navbar);
