import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Button } from "rebass/styled-components";
import Container from "./Container";
import { withTheme } from "styled-components";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

const CustomBreadcrumb = ({ data, customCrumbLabel, lang }) => {
  if (data) {
    return (
      <Container
        id={lang == "fr" ? "breadcrumb-fr" : "breadcrumb-en"}
        sx={{
          "ol.breadcrumb__list": {
            display: "flex",
            listStyle: "none",
            p: 0,
            pt: 4,
            m: 0,
            overflowX: "auto",
            flexWrap: "nowrap",
            "li.breadcrumb__list__item": {
              whiteSpace: "nowrap",
              "a.breadcrumb__link": {
                color: "muted",
                fontSize: 2
              },
              "&:not(:last-child)": {
                "a.breadcrumb__link": {
                  color: "title",
                  textTransform: "uppercase",
                  fontWeight: 600,
                  "&:hover": {
                    color: "primary",
                    "&:before": {
                      color: "primary"
                    }
                  }
                }
              },
              "&:first-child": {
                "a.breadcrumb__link": {
                  color: "white",
                  width: "14px",
                  overflow: "hidden",
                  display: "block",
                  height: "20px",
                  "&:before": {
                    content: "'\\e802'",
                    fontFamily: "fontello",
                    color: "title"
                  }
                }
              }
            },
            "span.breadcrumb__separator": {
              color: "primary",
              px: 2
            }
          },
          "&#breadcrumb-en": {
            "ol.breadcrumb__list": {
              "li.breadcrumb__list__item": {
                "&:first-child": {
                  display: "none"
                },
                "&:nth-child(3)": {
                  "a.breadcrumb__link": {
                    color: "white",
                    width: "14px",
                    overflow: "hidden",
                    display: "block",
                    height: "20px",
                    "&:before": {
                      content: "'\\e802'",
                      fontFamily: "fontello",
                      color: "title"
                    }
                  }
                },
              },
              "span.breadcrumb__separator": {
                "&:first-of-type": {
                  display: "none"
                }
              }
            }
          }
        }}
      >
        <Breadcrumb crumbs={data.crumbs} crumbLabel={customCrumbLabel} />
      </Container>
    );
  }
  return <></>;
};

CustomBreadcrumb.propTypes = {
  crumbs: PropTypes.shape({
    crumbLabel: PropTypes.any,
    pathname: PropTypes.any
  })
};

export default withTheme(CustomBreadcrumb);
