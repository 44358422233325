import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Container = ({ fluid, maxContainer, children, ...props }) => {
  fluid = fluid || false;
  maxContainer= maxContainer || false;
  return (
    <Box variant={fluid ? "containerFluid" : "container"} {...props}>
      <Box maxWidth={maxContainer ? "975px" : "none"} mx="auto">
        {children}
      </Box>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  fluid: PropTypes.bool
};

export default Container;
