import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Section = props => {
  return (
    <Box width="100%" py={[5, 5, 10]} {...props}>
      {props.children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.any
};

export default Section;
