import { theme } from "../cms/data/settings";
const colors = {
  gray30: "rgba(160, 169, 186, 0.3)",
  warningHover: "#ffb36d",
  primary: "#caa875",
  gray10: "rgba(160, 169, 186, 0.1)",
  success: "#009f78",
  danger: "#dd4b39",
  gray: "#d8d8d8",
  footerBottom: "#1d1d1d",
  secondaryHover: "#ff3cff",
  muted: "#949494",
  text: "#4a4a4a",
  background: "#fafafa",
  textHover: "#4a4a4a",
  grayLight: "#e0e0e0",
  primaryLight: "#9dcb21",
  highlight: "rgba(160, 169, 186, 0.5)",
  secondary: "#8e00ff",
  title: "#2a2a2a",
  grayDark: "#949494",
  dangerHover: "#fc7564",
  successHover: "#00d8a2",
  primaryHover: "#caa875",
  footer: "#222222",
  warning: "#ff8d24",
  gray50: "rgba(42, 169, 186, 0.5)",
  gradiantTitle: "linear-gradient(to right, #fafafa, #e0e0e0)",
  gradiantBanner: "linear-gradient(to left, rgba(224, 224, 224, 0), #e0e0e0)",
  ...theme.colors
};

export default colors;
