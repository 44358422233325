import PropTypes from "prop-types";
import React from "react";
import { siteinfos } from "../cms/data/settings";
import Navbar, { NavbarRaw } from "./Navbar";
import Footer, { FooterRaw } from "./Footer";
import { MaintenanceContent } from "./maintenance";
import loadable from "@loadable/component";

import { ThemeProvider } from "styled-components";
import { GlobalStyle, SiteWrapper } from "../theme/Site.style";
import theme from "../theme";

const CookiesBanner = loadable(() => import("./CookiesBanner"));
const Layout = ({ children, raw, lang }) => {
  raw = raw || false;
  const menusSettings = require("../cms/data/settings").menus;
  const footerSettings = require("../cms/data/settings").footer;

  siteinfos.isInMaintenanceMode =
    process.env.NODE_ENV === "development" ? false : siteinfos.isInMaintenanceMode;
  if (raw) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SiteWrapper>
          {!siteinfos.isInMaintenanceMode && (
            <NavbarRaw menusSettings={menusSettings} theme={theme} lang={lang} />
          )}
          {children}
          {!siteinfos.isInMaintenanceMode && (
            <FooterRaw
              menusSettings={menusSettings}
              theme={theme}
              footerSettings={footerSettings}
              lang={lang}
            />
          )}
        </SiteWrapper>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SiteWrapper>
        {!siteinfos.isInMaintenanceMode && (
          <>
            <Navbar lang={lang} />
            {children}
            <Footer menusSettings={menusSettings} footerSettings={footerSettings} theme={theme} lang={lang} />
          </>
        )}
        {siteinfos.isInMaintenanceMode && <MaintenanceContent />}
        <CookiesBanner siteinfos={siteinfos} bypass={siteinfos.isInMaintenanceMode} />
      </SiteWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  raw: PropTypes.bool,
  theme: PropTypes.any
};

export default Layout;
