import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Button, Text } from "rebass/styled-components";
import Link from "./Link";

const LangSwitcher = ({ lang, ...props }) => {
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  const sx = {
    position: "relative",
    bg:"white",
    overflow: "hidden",
    display: "flex",
    flexDirection: lang == "fr" ? ["row","row","row","column"] : ["row","row","row","column-reverse"],
    "a": {
      display:"block",
      py:1,
      px:2,
      mx:[1,1,1,0],
      width:["auto","auto","auto","100px"],
      borderWidth:["1px","1px","1px","0"],
      borderStyle: "solid",
      borderColor: "grayLight",
      transition: "200ms background ease",
      "&:not(.active)": {
        position: ["initial","initial","initial","absolute"],
        opacity: ".6",
        top: "100%",
        left: 0,
      },
      "button": {
        display: "flex",
        alignItems: "center",
        "img": {
          display: "block",
          width: "30px",
          height: "auto",
          mr: 2
        },
        "div": {
          whiteSpace: "nowrap",
          fontWeight: 600
        }
      }
    },
    "&:hover": {
      boxShadow:["none","none","none","headerLight"],
      "a": {
        position: "initial",
        "&:hover": {
          opacity: "1",
          borderColor:["gray","gray","gray","transparent"],
        }
      }
    }
  };
  return (
    <Box sx={sx}>
      <Link href="/" className={lang == "fr" ? "active" : ""}>
        <Button variant="ninja">
          <img src="/img/flag-fr.svg" />
          <Text>FR</Text>
        </Button>
      </Link>
      <Link href="/en" className={lang == "en" ? "active" : ""}>
        <Button variant="ninja">
          <img src="/img/flag-uk.svg" />
          <Text>EN</Text>
        </Button>
      </Link>
    </Box>
);
};

LangSwitcher.propTypes = {
  lang: PropTypes.any
};

export default LangSwitcher;