import styled, { css, createGlobalStyle } from "styled-components";
import "./reset.css";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { normalize } from "polished";

const fontelloCss = css`
  ${import("../components/UI/Fontello/fontello/css/fontello.css")}
`;

const GlobalStyle = createGlobalStyle`
${normalize()}
${fontelloCss}
*{
  font-family: ${themeGet("fonts.body")};
}
h1,h2,h3,h4,h5,h6{
  font-family: ${themeGet("fonts.heading")};
}
.drawer {
  top: 60px !important;
  @media screen and (min-width: ${themeGet("breakpoints.0")}) {
    top: 90px !important;
  }
}
.drawer-content-wrapper,
.drawer-content{
  background-color:#fff !important;
  color: ${themeGet("colors.text")};
}
.drawer.drawer-open .drawer-mask {
  opacity: .7 !important;
}
body.ant-scrolling-effect {
  width: 100% !important;
}
// body.ant-scrolling-effect .sticky {
//   width: 100vw;
// }
html {
  line-height: normal;
}
h2 {
  font-size:${themeGet("fontSizes.7")}px;
  text-transform: uppercase;
  font-weight: normal;
  margin-top: ${themeGet("space.10")}px;
}
body {
  background-color:${themeGet("colors.background")};
  font-family: ${themeGet("fonts.body")};
  color: ${themeGet("colors.text")};
  font-size:${themeGet("fontSizes.3")}px;
  margin: 0;
  #page--content {
    div {
      p {
        &:first-child {
          margin-top: 0;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          position: relative;
          padding-bottom: ${themeGet("space.2")}px;
          padding-left: ${themeGet("space.4")}px;
          &:before {
            content: "";
            width: 8px;
            height: 6px;
            background: ${themeGet("colors.primary")};
            margin: 7px 15px 0 0;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    blockquote {
      padding: 0 0 0 ${themeGet("space.4")}px;
      margin: ${themeGet("space.4")}px 0;
      border-left 2px solid ${themeGet("colors.primary")};
    }
    #contact--form,
    #contact-us--form {
      & > div > div {
        border-bottom: 1px solid ${themeGet("colors.grayLight")};
      }
    }
  }
}
*{
  font-family: ${themeGet("fonts.body")};
  &:focus {
  outline: none;
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0px;
}
a{
  color:inherit;
  text-decoration:inherit;
  font-size:inherit;
  font-weight:inherit;
  word-break: break-word;
}
  }
  img{
    max-width:100%;
  }
  .react-parallax-bgimage{
    max-width:unset;
  }
  .mapboxgl-ctrl-attrib{
    display:none !important;
  }
`;

const SiteWrapper = styled.div`
  /* Style system supported prop */
  

  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
